var site = site || {};

site.direction = site.direction || {};

(function ($) {
  Drupal.behaviors.tabbedProductsBlockV2 = {
    attach: function (context) {
      var self = this;
      var $modules = $('.js-tabbed-products-block-v2', context);
      var $activeGrid;
      var $activeCarousels;

      $modules.each(function () {
        var $module = $(this);
        var $trigger = $('.js-tabbed-products-block-trigger:not(.disabled)', $module);
        var $content = $('.js-tabbed-products-block-content', $module);
        var $firstTabLabel = $('.js-tabbed-products-block-trigger', $module).first();
        var $firstTab = $('.js-tabbed-products-block-content', $module).first();

        $firstTabLabel.add($firstTab).addClass('current');
        $trigger.off('click.tabbedBlock').on('click.tabbedBlock', function (e) {
          var index = $trigger.index($(this));

          e.preventDefault();
          self.switchToIndex(index, $trigger, $content);
        });
      });

      $activeGrid = $('.current .js-product-grid', $modules);
      $activeCarousels = $('.js-product-brief-carousel-items', $activeGrid);
      $activeCarousels.each(function () {
        self.initGrid($(this));
      });
    },

    switchToIndex: function (index, $trigger, $content) {
      var self = this;
      var $activeContent = $content.eq(index);
      var $swatchList = $('.js-product-brief-shades__grid', $activeContent);
      var $deferredCarousel = $();

      $swatchList.resize();
      if ($activeContent.hasClass('first-seen-processed')) {
        self.switchToIndexNow($content, $activeContent, $trigger, index);
      }
      // only load the defered grids
      $activeContent.once('first-seen', function () {
        $deferredCarousel = $('.js-product-brief-carousel-items', $activeContent);
        $deferredCarousel.data('isDeferred', 1);
        $deferredCarousel.on('grid.carousel.deferloaded', function () {
          self.switchToIndexNow($content, $activeContent, $trigger, index);
          $deferredCarousel.off('grid.carousel.deferloaded');
        });
        self.loadDeferredCarousel($deferredCarousel);
      });
    },

    switchToIndexNow: function ($content, $activeContent, $trigger, index) {
      // Show this tab content
      $content.add($trigger).removeClass('current');
      $activeContent.addClass('current');
      $trigger.eq(index).addClass('current');
    },

    initGrid: function ($carousel) {
      var self = this;
      var $this = $carousel;
      var $arrowsDiv = $this.parent().find('.carousel-controls');
      var slidesToShow = JSON.parse(
        $this.closest('.js-product-grid').attr('data-grid-items-per-row')
      );
      var settings = {
        rtl: site.direction.isRTL,
        appendArrows: $arrowsDiv,
        infinite: true,
        slidesToShow: slidesToShow.large,
        slidesToScroll: slidesToShow.large,
        onSetPosition: function (_slick) {
          _slick.$slider.trigger('afterSetPositionTabbed', _slick);
        },
        onAfterChange: function (_slick, _curentSlide) {
          _slick.$slider.trigger('afterChangeTabbed', _slick, _curentSlide);
        },
        onBeforeChange: function (_slick, _currentSlide, _nextSlide) {
          _slick.$slider.trigger('beforeChangeTabbed', _slick, _currentSlide, _nextSlide);
        },
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              arrows: true,
              dots: false,
              slidesToShow: slidesToShow.medium,
              slidesToScroll: slidesToShow.medium
            }
          },
          {
            breakpoint: 768,
            settings: {
              arrows: true,
              dots: false,
              slidesToShow: slidesToShow.small,
              slidesToScroll: slidesToShow.small
            }
          }
        ]
      };

      $this.on('afterChangeTabbed', function () {
        if ($this.data('isDeferred')) {
          $this.once('lazyload-shade-links', function () {
            $(document).trigger('grid.carousel.lazyloaded', {
              gridCarousel: $this,
              selector: '.js-product-grid'
            });
            $this.off('afterChangeTabbed');
          });
        }
      });
      $this.on('beforeChangeTabbed', function (e, _slick) {
        if ($this.data('isDeferred')) {
          self.loadBoundaryShades(_slick);
          $this.off('beforeChangeTabbed');
        }
      });
      $this.on('afterSetPositionTabbed', function (e, _slick) {
        if (_slick.slideWidth > 0) {
          if ($this.find('img.lazyloaded').length > 0) {
            // If the images are loaded we can unbind the listener
            $this.off('afterSetPositionTabbed');
          }
          $this
            .once('grid-carousel-slick-visible')
            .trigger('grid.carousel.visible', { gridCarousel: $this });
          if ($this.data('isDeferred')) {
            $this.once('init-deferred', function () {
              self.completeDeferredLoad($this);
            });
          } else {
            self.waitForImages($this);
          }
        }
      });
      // Init this carousel with our settings
      $this.slick(settings);
    },

    loadBoundaryShades: function (_slick) {
      var _nextSlide = _slick.currentSlide + _slick.options.slidesToShow;
      var _prevSlide = _slick.currentSlide - _slick.options.slidesToScroll;

      if (_nextSlide) {
        $(document).trigger('grid.carousel.lazyloaded', {
          gridCarousel: _slick.$slider,
          selector: '.js-product-grid[index="' + _nextSlide + '"]'
        });
      }
      if (_prevSlide) {
        $(document).trigger('grid.carousel.lazyloaded', {
          gridCarousel: _slick.$slider,
          selector: '.js-product-grid[index="' + _prevSlide + '"]'
        });
      }
    },

    completeDeferredLoad: function ($grid) {
      var self = this;

      self.waitForImages($grid);
      $(document).trigger('grid.carousel.lazyloaded', {
        gridCarousel: $grid,
        selector: '.js-product-grid.slick-active'
      });
      $grid.trigger('grid.carousel.deferloaded');
    },

    waitForImages: function ($grid) {
      var $items = $('.js-product-grid-item', $grid);
      var $img = $('img', $items);

      // Items get height 0 on slick init, need to remove it so the item show ok
      $items.once('slick-load-height').css({ height: '' });

      if ($items.find('img.lazyloaded').length > 0) {
        $items.matchHeight();
      } else {
        $img.on('load', function () {
          $items.matchHeight();
          $img.off('load');
        });
      }
    },

    loadDeferredCarousel: function ($deferredCarousel) {
      var self = this;

      $deferredCarousel.closest('.js-tabbed-products-block-content').removeClass('hidden');
      self.initGrid($deferredCarousel);
    }
  };
})(jQuery);
